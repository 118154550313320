import React from 'react'
import img from '../components/img/404.png'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: rgb(0, 36, 71);
  }
`

const Error = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  color: white;
  font-size: 20px;

  p {
    margin: 0 auto;
    max-width: 500px;
  }

  img {
    max-width: 700px;
    margin: 0 auto;
  }

  button {
    appearance: none;
    outline: 0;
    background-color: white;
    border: 0;
    padding: 10px 15px;
    border-radius: 5px;
    width: 250px;
    cursor: pointer;
    font-size: 20px;
    transition-duration: 0.25s;
    margin-top: 15px;
  }
`

class NotFoundPage extends React.Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <Error>
          <img src={img} alt="" />
          <p>
            Страница на реконструкции. Чтобы вернуться назад, нажмите на кнопку
            внизу
          </p>
          <button onClick={() => history.go(-1)}>Вернуться назад</button>
        </Error>
      </>
    )
  }
}

export default NotFoundPage
